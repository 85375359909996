// types
import { DefaultConfigProps } from "types/config";

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = "#1DA1F2";
export const facebookColor = "#3b5998";
export const linkedInColor = "#0e76a8";

export const APP_DEFAULT_PATH = "/dashboard/";
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 90;
export const HEADER_HEIGHT = 74;

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/react/default'
export const APP_BASE_NAME = "";

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `Inter var`,
  i18n: "en",
  menuOrientation: "vertical",
  menuCaption: true,
  miniDrawer: false,
  container: false,
  mode: "light",
  presetColor: "theme1",
  // presetColor: "default",
  themeDirection: "ltr",
  themeContrast: false,
};

export default config;
