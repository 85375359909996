import { createContext, useEffect, useReducer, ReactElement } from "react";

// third-party
//import { Chance } from "chance";

// reducer - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";
import { openSnackbar } from "store/reducers/snackbar";
import { dispatch as reduxDispatch } from 'store';
//import { AxiosResponse } from 'axios';



// project-imports
import Loader from "components/Loader";
import axios from "utils/axios";
// import axios from "axios";
import { AuthProps, JWTContextType } from "types/auth";



// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  
};

const setSession = (user?: string | null) => {
  if (user) {
    localStorage.setItem("user", user);
  } else {
    localStorage.removeItem("user");
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          setSession(user);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: JSON.parse(user),
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (user_name: string, password: string) => {
    try {
      const response = await axios.post("users/login/", {
        user_name,
        password,
      });
      const { user,status,message } = response.data;

      if(status){
        reduxDispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: "alert",
            alert: {
              color: "success",
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            close: false,
          })
        );       

        const stringData = JSON.stringify(user);
        setSession(stringData);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user,
          },
        });

      }else{
        reduxDispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: "alert",
            alert: {
              color: "error",
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            close: false,
          })
        ); 
      
      }    

    } catch (error) {
      console.log(error);
      // alert(JSON.stringify(error));
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => {};
  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,       
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
