import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";


const DashboardDefault = Loadable(
  lazy(() => import("pages/dashboard/default"))
);
const MeetupsList = Loadable(
  lazy(() => import("pages/meetups/list"))
);
const ConnectionsList = Loadable(
  lazy(() => import("pages/connections/list"))
);
const AppreciationList = Loadable(
  lazy(() => import("pages/appreciations/list"))
);
const TestimonialsList=Loadable(
  lazy(() => import("pages/testimonials/list"))
)
const ReportsList=Loadable(
  lazy(() => import("pages/reports/list"))
)
const SummaryReportList=Loadable(
  lazy(() => import("pages/summaryreports/list"))
)
const MemberGrowthList=Loadable(
  lazy(() => import("pages/membergrowth/list"))
)
const GrowthReportList=Loadable(
  lazy(() => import("pages/growthreports/list"))
)
const TrainingsList = Loadable(
  lazy(() => import("pages/trainings/list"))
);

// pages routing
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/register")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/forgot-password"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/auth/reset-password"))
);
const AuthCheckMail = Loadable(lazy(() => import("pages/auth/check-mail")));
const AuthCodeVerification = Loadable(
  lazy(() => import("pages/auth/code-verification"))
);

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <DashboardDefault />
        },
        
        {
          path: "meetups",
          element: <MeetupsList />,
        },
        {
          path: "connections",
          element: <ConnectionsList />,
        },
        {
          path: "appreciations",
          element: <AppreciationList />,
        },
        {
          path: "business",
          element: <AppreciationList />,
        },
        {
          path: "testimonials",
          element: <TestimonialsList />,
        },
        {
          path: "trainings",
          element: <TrainingsList />,
        },
        {
          path: "reports",
          element: <ReportsList />,
        },
        {
          path: "summaryreports",
          element: <SummaryReportList />,
        },

        {
          path: "membergrowth",
          element: <MemberGrowthList />,
        },

        {
          path: "growthreports",
          element: <GrowthReportList />,
        },



        
      ],
    },
 
    {
      path: "/auth",
      element: <CommonLayout />,
      children: [
        {
          path: "login",
          element: <AuthLogin />,
        },
        {
          path: "register",
          element: <AuthRegister />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />,
        },
        {
          path: "code-verification",
          element: <AuthCodeVerification />,
        },
      ],
    },
  ],
};

export default MainRoutes;
