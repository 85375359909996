import axios from "axios";

// Create a new Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    'X-API-KEY': 'funda123', 
  },
});

export default axiosInstance;
